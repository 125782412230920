<template>  
  <!-- 表格分页组件 -->
  <!-- onShowSizeChange  切换每页条数 -->
  <!-- change 切换页码 -->
  <!-- pageSizeOptions 每页可以显示多少条 -->
    <div class="tablePage">
      <a-pagination 
        showQuickJumper
        showSizeChanger
        :total="pager.totalCount"
        v-model:current="pager.currPage"
        @change="onChange"
        v-model:page-size="pager.pageSize"
        @showSizeChange="onShowSizeChange"
        :pageSizeOptions="pager.pageSizeOptions"
        :show-total="total => `共有 ${pager.totalCount} 条`"
      />
    </div>
  </template>

  <script setup>
  import {reactive,toRefs,defineProps,defineEmits,watch} from 'vue'
  const props = defineProps({
    parentPager: {
      type: Object,
      default: null
    }
  })
  const $emit = defineEmits(['handleChangePage'])
  // 定义双向绑定参数
  const stata = reactive({
    pager:{
      pageSizeOptions: ["10", "20", "30", "40", "50"],
      currPage: 1,
      pageSize: 10,
      totalCount: 0,
    }
  })
  //参数导出
  // const { parentPager } = toRefs(props)
  const {pager} = toRefs(stata)
  watch(
    props,
    ()=>{
      stata.pager = Object.assign({}, stata.pager,props.parentPager);
    }
  )
  // props过来的参数
  const onChange = (currPage,pageSize) =>{
    stata.pager = Object.assign({},stata.pager,{currPage,pageSize})
    $emit("handleChangePage",stata.pager)
  }
  const onShowSizeChange = (currPage,pageSize) =>{
    stata.pager = Object.assign({},stata.pager,{currPage,pageSize})
    $emit("handleChangePage",stata.pager)
  }

</script>
<style scoped lang="less">
  .tablePage{
    text-align:right;
    margin:20px 0;
    padding-right:30px;
  }
  </style>