<template>  
  <!-- 表格头部搜索框 -->
  <div class="searchStyle">
    <a-form
        layout="inline" 
        :model="formInline"
        @finish="handleFinish"
      >
      <a-form-item 
         v-for="(item,index) in formList" 
        :key="'formModalItem_'+index"
        :label="item.label"
        >
        <a-input 
          v-if="item.type == 'input'"
          v-model:value="formInline[item.name]"
          :placeholder="item.placeholder"
          style="width:200px"
          allowClear
        ></a-input>
         <a-select           
          style="width: 200px;"
          v-if="item.type=='select'"
          v-model:value="formInline[item.name]"
          :allowClear="item.allowClear=='false'?false:true"
          :placeholder="item.placeholder"
         >
         <a-select-option
            v-for="(it,idx) in item.options"
            :key="'formModelOption_'+idx"
            :value="item.optionValue?it[item.optionValue]:it['value']"
          >{{item.optionLabel?it[item.optionLabel]:it['label']}}</a-select-option>
        </a-select>
      </a-form-item>
        <a-form-item>
        <a-button type="primary" html-type="submit">查询</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script setup>
  import { reactive,toRefs,defineProps,defineEmits} from 'vue'
  const props = defineProps({
     formList: {
      type: Array,
      default: null
    }
  })
  const emits = defineEmits(['handleSubmit'])
  // 定义双向绑定参数
  const state = reactive({
    formInline:{ 
    }
  })
  //参数导出
  const { formList } = toRefs(props)
  const { formInline } = toRefs(state)
  //方法
  const handleFinish = () =>{
    emits('handleSubmit',formInline.value)
  }
</script>
<style scoped lang="less">

</style>                                                                                                                         