<template>
  <div class="search-box">
    <SearchFrom :formList="formList" @handleSubmit="handleSubmit" />
  </div>
  <div class="btn-group">
    <div></div>
    <a-button type="primary" @click="addCase">+&nbsp;新增</a-button>
  </div>
  <a-table :dataSource="dataSource" :columns="columns" :pagination="false">
    <template #bodyCell="{ column,record,index }">
      <template v-if="column.dataIndex === 'exampleContext'">
        <div class="ellipsis" v-html="record.exampleContext"></div>
      </template>
      <template v-if="column.dataIndex === 'status'">
        <a-switch :checked="record.status=='1'?true:false" @change="switchChange($event,record)" />
      </template>
      <template v-if="column.dataIndex === 'operation'">
        <!-- <img @click="relatedCon(record.id)" src="@/assets/img/edit.png" title="编辑" /> -->
        <a @click="editCase(record.id)">编辑</a>
        <a @click="deleteCase(record.id)">删除</a>
      </template>
    </template>
  </a-table>
  <TablePagination :parentPager="pager" @handleChangePage="handleChangePage" />
</template>
<script setup>
import { reactive,toRefs,getCurrentInstance,createVNode} from 'vue'
import SearchFrom from '../../components/searchFrom'
import TablePagination from '../../components/Pagination'
import { InfoCircleOutlined } from '@ant-design/icons-vue';
  import { Modal, message } from 'ant-design-vue';
import { useRouter } from 'vue-router';
const { proxy } = getCurrentInstance()
  let $api = proxy.$api
const router = useRouter()
const columns = [
{
        title: "优先级",
        dataIndex: "priority",
      },
      {
        title: "案例名称",
        dataIndex: "exampleName",
      },
      {
        title: "案例分类",
        dataIndex: "exampleType",
        customRender:(text)=>{
          let list = JSON.parse(JSON.stringify(state.typeList))
          let aa = '';
          list.forEach((item)=>{
            if(item.value == text.value){
              aa = item.label;
            }
          })
          return aa
        }
      },
      {
        title: "案例描述",
        dataIndex: "exampleContext",
        // ellipsis:true
      },
      {
        title: "启用",
        dataIndex: "status",
      },
      {
        title: '操作',
        dataIndex: 'operation'
      },
]
 
const state = reactive({
  pager:{
    currPage:1,
    pageSize:20
  },
  searchValues:{},
  dataSource:[],
  formList:[
  {
    label: '案例名称',
    name: 'exampleName',
    type: 'input',
    placeholder: '请输入',
    allowClear: true
  },
  {
    label: '案例分类',
    name: 'exampleType',
    type: 'select',
    placeholder: '请选择',
    options: [],
    allowClear: true
  },
  {
    label: '案例描述',
    name: 'exampleContext',
    type: 'input',
    placeholder: '请输入',
    allowClear: true
  },
],
typeList:[]
})
const {pager,dataSource,formList,typeList}=toRefs(state)
const addCase = ()=>{
  router.push({path:'/manage/addCase'})
}

const switchChange = (e,record)=>{
  console.log(e,record)
  $api.enableStatus({id:record.id,status:e?1:0}).then(res=>{
    if(res.code == 200){
      getList()
    }
  })
}

const deleteCase = (id)=>{
  Modal.confirm({
      title: '是否确认删除',
      icon: createVNode(InfoCircleOutlined, {
        type: 'info-circle-outlined',
        theme: 'filled',
        style: {
          color: 'red',
        },
      }),
      okText: '确认',
      cancelText: '取消',
      onOk() {
        $api.deleteCase({id}).then(res=>{
          message.success('删除成功');
          getList();
        })
      },
      onCancel() {

      },
    });
}

const editCase = (id)=>{
  router.push({path:'/manage/addCase',query:{id}})
}

console.log(sessionStorage.getItem('token'),'Authorization')
const getList = ()=>{
  $api.getCaseList({...state.pager,...state.searchValues}).then(res=>{
    console.log('列表',res)
    if(res&&res.code&&res.code == 200){
      state.dataSource = res.data.list;
      let{currPage,pageSize,totalCount} = res.data;
      state.pager = {currPage,pageSize,totalCount}
    }
  })
}
getList();
const getDictionaryByCode = ()=>{
  $api.getDictionaryByCode({code:'EXAMPLE_TYPE'}).then(res=>{
    console.log(res,'字典')
    if(res&&res.code&&res.code == 200){
      
      state.formList[1].options = res.data.map(item=>{
        return{
          label: item.dicvalue,
          value: item.dickey,
        }
      })
      state.typeList = state.formList[1].options;
    }
  })
}
getDictionaryByCode()
const relatedCon = ()=>{

}
const handleChangePage = (pager) => {
  let {currentPage,pageSize}= pager;
  state.pager = {currentPage,pageSize,page:currentPage};
  getList();
}
const handleSubmit = (data)=>{
  state.searchValues = data;
  getList();
}
</script>
<style lang="less" scoped>
.search-box{
  padding: 0 23px;
  height: 74px;
  display: flex;
    align-items: center;
    border-bottom: 1px solid #f7f7f7;
}
.ellipsis{
  width: 300px;
  height: 22px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  
}
.btn-group{
  padding: 20px;
  display: flex;
  justify-content: space-between;
}
.ant-table a {
    margin-right: 10px;
  }
</style>